import $ from 'jquery'
import gsap from 'gsap'

function showContactForm(event) {
    event.preventDefault();
    const formContainer = $('.js-cta-form-container');
    //formContainer.html('<iframe class="contact-cta__iframe" src="/contact-form" width="100%""></iframe>')
    const form = formContainer.data('form');
    formContainer.html(form)
    $('.js-connect-button').attr('aria-expanded', 'true');
    let openForm = gsap.timeline({delay: 0.5});
    openForm
    .addLabel("open-start")
    .to(".js-connect-top", {duration: 1,autoAlpha: 0,height: 0, ease: "power2.inOut"},"open-start")
    .to(".js-connect-form", {duration: 1,autoAlpha: 1,height: 'auto', ease: "power2.inOut"},"open-start");
}

function closeContactForm(event) {
    event.preventDefault();
    $(this).addClass('is-clicked');
    $('.js-connect-button').attr('aria-expanded', 'false');
    let closeForm = gsap.timeline({delay: 0.5});
    closeForm
    .addLabel("close-start")
    .to(".js-connect-top", {duration: 1,autoAlpha: 1,height: 'auto', ease: "power2.inOut"},"close-start")
    .to(".js-connect-form", {duration: 1,autoAlpha: 0,height: 0, ease: "power2.inOut"},"close-start");
}

$(document).on('click', '.js-connect-button', showContactForm )
$(document).on('click', '.js-connect-close', closeContactForm )
